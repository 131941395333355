<template>
  <v-card
    class="teros-elevation"
    :color="tableColor"
  >
    <v-card-title
      class="px-5 pb-0 text-uppercase v-title-cadastro"
    > 
      Controle de Gastos com Terceiros 
    </v-card-title>
    <v-card-text class="pb-6">
      <div class="d-flex pt-1">
        <div class="table-v-action-button mr-3 pt-1" @click="doLoad()">
          <v-icon>mdi-refresh</v-icon>Atualizar
        </div>
        <monthly-filter
          v-model="competencia"
          @change="doLoad()"
          class="mr-3"
          style="width: 260px"
        ></monthly-filter>
        <ServicoSelect class="mb-n1 mt-0 mr-3" style="max-width: 255px" v-model="servico" @change="doLoad()"></ServicoSelect>
      </div>
    </v-card-text>
    <v-data-table
      data-app
      :items="relatorioFormatado"
      :headers="headers"
      :footer-props="{
            'items-per-page-options': [-1]
          }"
      hide-default-footer
      fixed-header
    >
      <template v-slot:item="{ item, headers }">
        <tr class="table-v-tr" style="cursor: default">
          <td
            v-for="col in headers"
            v-bind:key="col.value"
            :class="{ 'nowrap': col.nowrap, 'text-end': col.align == 'end' }"
          >{{ item[col.value] ? (col.format ? col.format(item[col.value]) : item[col.value]) : '-' }}</td>
        </tr>
      </template>
      <template v-slot:[`body.append`]="append">
        <tr class="table-v-tr">
          <th
            style="cursor: default; font-size: 13px !important"
            v-for="col in append.headers"
            v-bind:key="col.value"
            :class="{ 'nowrap': col.nowrap, 'text-end': col.align == 'end' }"
          >{{ col.format ? col.format(totalFormatado[col.value]) : totalFormatado[col.value] }}</th>
        </tr>
      </template>
    </v-data-table>
    <v-card-text class="pt-0">
      <v-row>
        <v-col>
          <div class="font-weight-bold text-uppercase mb-3">Controle de Gastos Por Enquadramento</div>
          <div style="background: white;border-radius: 5px;padding: 20px;">
            <div style="height: 280px;margin: 0px auto;">
              <bar-chart
                style="height: 280px;"
                :chartdata="barStackedEnquadramento"
                :options="barStackedOptions"
              ></bar-chart>
            </div>
          </div>
        </v-col>
        <v-col>
          <div class="font-weight-bold text-uppercase mb-3">Controle de Gastos Por Terceiro</div>
          <div style="background: white;border-radius: 5px;padding: 20px;">
            <div style="height: 280px;margin: 0px auto;">
              <bar-chart
                style="height: 280px;"
                :chartdata="barStackedRazaoSocial"
                :options="barStackedOptions"
              ></bar-chart>
            </div>
          </div>
        </v-col>
        <v-col>
          <div class="font-weight-bold text-uppercase mb-3">Gasto Acumulado Por Enquadramento</div>
          <div style="background: white;border-radius: 5px;padding: 20px;">
            <div style="margin: 0px auto;">
              <pie-chart :styles="{height: '280px'}" :chartdata="pizza" :options="chartoptions"></pie-chart>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import * as moment from "moment";
import { mapGetters } from 'vuex';
export default {
  components: {
    "bar-chart": () => import("@/components/bar-chart.vue"),
    "monthly-filter": () => import("@/components/monthly-filter.vue"),
    "pie-chart": () => import("@/components/pie-chart.vue"),
    "ServicoSelect": () => import("@/components/servico-select.vue"),
  },
  computed: {
    ...mapGetters(["clientId"]),
    pizza: function () {
      return {
        datasets: [
          {
            data: this.relatorioPorEnquadramento.map((r) => r.total),
            backgroundColor: this.$chartColors,
            label: "Pizza",
          },
        ],
        labels: this.relatorioPorEnquadramento.map((r) =>
          r.enquadramento ? r.enquadramento : "N/A"
        ),
      };
    },
    barStackedRazaoSocial: function () {
      const TOP = 5;

      const topFornecedoresRazaoSocial = Object.fromEntries(
        [...this.relatorioPorRazaoSocial]
          .sort((r1, r2) => r2.total - r1.total)
          .slice(0, TOP)
          .map((r) => [r.id, r.total])
      );

      const topFornecedores = this.relatorio
        .filter((fornecedor) => topFornecedoresRazaoSocial[fornecedor.id])
        .sort(
          (r1, r2) =>
            topFornecedoresRazaoSocial[r2.id] -
            topFornecedoresRazaoSocial[r1.id]
        );

      const outrosFornecedores = this.relatorio
        .filter((fornecedor) => !topFornecedoresRazaoSocial[fornecedor.id])
        .reduce(
          (r1, r2) => {
            let c1 = Object.fromEntries(r1.competencia);
            r2.competencia.forEach(
              (c) => (c1[c[0]] = c1[c[0]] ? c1[c[0]] + c[1] : c[1])
            );
            r1.competencia = Object.entries(c1);
            return r1;
          },
          { razao_social: "Outros", competencia: [] }
        );

      const relatorioFinal = [...topFornecedores];
      if (outrosFornecedores.competencia.length > 0) {
        relatorioFinal.push(outrosFornecedores);
      }

      return {
        labels: this.rangeCompetencia.map((c) =>
          this.$options.filters.toMonth(c)
        ),
        datasets: relatorioFinal.map((row, idx) => {
          var competencias = Object.fromEntries(row.competencia);
          return {
            backgroundColor: this.$chartColors[idx],
            borderColor: this.$chartColors[idx],
            data: this.rangeCompetencia.map((c) =>
              competencias[c] ? competencias[c] : 0
            ),
            hidden: false,
            label:
              row.razao_social.length > 15
                ? row.razao_social.substring(0, 15) + "..."
                : row.razao_social,
          };
        }),
      };
    },
    barStackedEnquadramento: function () {
      return {
        labels: this.rangeCompetencia.map((c) =>
          this.$options.filters.toMonth(c)
        ),
        datasets: this.relatorioPorEnquadramento.map((row, idx) => {
          return {
            backgroundColor: this.$chartColors[idx],
            borderColor: this.$chartColors[idx],
            data: this.rangeCompetencia.map((c) =>
              row.competencias[c] ? row.competencias[c] : 0
            ),
            hidden: false,
            label: row.enquadramento ? row.enquadramento : "N/A",
          };
        }),
      };
    },
    relatorioPorEnquadramento: function () {
      return Object.values(
        this.relatorio.reduce((r, a) => {
          r[a.enquadramento] = r[a.enquadramento] || {
            enquadramento: a.enquadramento,
            competencias: {},
            total: 0,
          };
          a.competencia.forEach((comp) => {
            r[a.enquadramento].competencias[comp[0]] =
              r[a.enquadramento].competencias[comp[0]] || 0;
            r[a.enquadramento].competencias[comp[0]] += comp[1];
            r[a.enquadramento].total += comp[1];
          });
          return r;
        }, {})
      );
    },
    relatorioPorRazaoSocial: function () {
      return Object.values(
        this.relatorio.reduce((r, a) => {
          r[a.id] = r[a.id] || {
            razao_social: a.id,
            id: a.id,
            competencias: {},
            total: 0,
          };
          a.competencia.forEach((comp) => {
            r[a.id].competencias[comp[0]] =
              r[a.id].competencias[comp[0]] || 0;
            r[a.id].competencias[comp[0]] += comp[1];
            r[a.id].total += comp[1];
          });
          return r;
        }, {})
      );
    },
    relatorioFormatado: function () {
      return this.relatorio.map((r) => {
        var competencias = Object.fromEntries(r.competencia);
        return {
          ...r,
          ...competencias,
          total: this.rangeCompetencia
            .map((c) => (competencias[c] ? competencias[c] : 0))
            .reduce((a, b) => a + b),
        };
      });
    },
    totalFormatado: function () {
      return this.relatorioFormatado.reduce(
        (r1, r2) => {
          var sum = Object.fromEntries(
            this.rangeCompetencia.map((c) => [
              c,
              (r1[c] ? r1[c] : 0) + (r2[c] ? r2[c] : 0),
            ])
          );
          sum.total = r1.total + r2.total;
          sum.razao_social = "Total";
          return sum;
        },
        { total: 0 }
      );
    },
    barStackedOptions: function () {
      return {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          callbacks: {
            label: (tooltipItem) => {
              return this.$options.filters.toCurrency(tooltipItem.value);
            },
          },
        },
        scales: {
          xAxes: [
            {
              stacked: true,
            },
          ],
          yAxes: [
            {
              stacked: true,
              ticks: {
                callback: (value) => {
                  return this.abbrNumber(value);
                },
              },
            },
          ],
        },
      };
    },
    chartoptions: function () {
      return {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              const total = data.datasets[0].data.reduce((a, b) => a + b, 0);
              const percent = (data.datasets[0].data[tooltipItem.index]*100 / total).toFixed(0)+"%"
              return `${this.$options.filters.toCurrency(
                data.datasets[0].data[tooltipItem.index]
              )} (${percent})`;
            },
          },
        },
      };
    },
    isClient: function () {
      return this.getClient().isClient;
    },
    resource: function () {
      return this.apiResource(
        `/v1/fiscal/nfservico/${this.clientId}/prestadores`
      );
    },
    rangeCompetencia: function () {
      var mIni = moment(this.competencia[0]);
      var mFim = moment(this.competencia[1]);
      var diff = mFim.diff(mIni, "months");
      if (diff < 0) {
        mIni = moment(this.competencia[1]);
        mFim = moment(this.competencia[0]);
        diff = mFim.diff(mIni, "months");
      }
      var range = [];
      range.push(mIni.format("YYYY-MM"));
      for (var i = 0; i < diff; i++) {
        range.push(mIni.add(1, "months").format("YYYY-MM"));
      }
      return range;
    },
    competenciaFilter: function () {
      var mIni = moment(this.competencia[0]);
      var mFim = moment(this.competencia[1]);
      var diff = mFim.diff(mIni, "months");
      if (diff < 0) {
        mIni = moment(this.competencia[1]);
        mFim = moment(this.competencia[0]);
      }
      return { ini: mIni.format("YYYY-MM"), fim: mFim.format("YYYY-MM") };
    },
  },
  mounted: function () {
    var ini = moment().startOf("quarter");
    var fim = moment(ini).add(2, "months");
    this.competencia = [ini.format("YYYY-MM"), fim.format("YYYY-MM")];
    this.doLoad();
  },
  methods: {
    doLoad: function () {
      this.resetHeaders();

      this.resource
        .get({
          query: `competenciaIni=${this.competenciaFilter.ini}&competenciaFim=${this.competenciaFilter.fim}&trabalho=${this.servico}`,
        })
        .then((response) => {
          if (!response.error) {
            this.totalRelatorio = { razao_social: "Total", total: 0 };
            this.relatorio = response[0].map((row) => {
              row.competencia = row.competencia.filter(
                (c) =>
                  c[0] >= this.competenciaFilter.ini &&
                  c[0] <= this.competenciaFilter.fim
              );
              return row;
            });
          }
        });
    },
    resetHeaders: function () {
      this.colunas.forEach((c) => {
        c.class = `vuetify-table-header${this.isClient ? "-cli" : ""}`;
      });
      this.headers = [
        ...this.colunas,
        ...this.rangeCompetencia.map((c) => ({
          value: c,
          text: this.$options.filters.toMonth(c),
          align: "end",
          class: `vuetify-table-header${this.isClient ? "-cli" : ""}`,
          nowrap: true,
          format: this.$options.filters.toCurrency,
        })),
        {
          text: "Total",
          value: "total",
          align: "end",
          class: `vuetify-table-header${this.isClient ? "-cli" : ""}`,
          nowrap: true,
          format: this.$options.filters.toCurrency,
        },
      ];
    },
  },
  data: function () {
    return {
      competencia: [],
      relatorio: [],
      totalRelatorio: {},
      headers: [],
      servico: null,
      colunas: [
        { text: "Razão Social", value: "razao_social" },
        { text: "Enquadramento", value: "enquadramento" },
      ],
    };
  },
  watch: {
    clientId: function () {
      this.doLoad();
    },
  },
};
</script>

<style>
</style>